


  if(Cookies.get('kook')) {
    $(".cookien-banner").css('display','none');
  }else {
    $(".cookien-banner").css('display','block');
  }


$(".kendu-btn").click(function(){
  $(".cookien-banner").css('display','none');
  Cookies.set('kook','bai',{expires: 1});
});


function toggleCP(){
	var cp = document.getElementById("cp");
	var izenburu = document.getElementById("izenburu");
    var js_menu_off=document.getElementById("js-menu-off");
    var js_logoa=document.getElementById("js-logoa");
	var headerra = document.getElementById("headerra");
	var eskuma = document.getElementById("eskuma");
	cp.style.height = window.innerHeight - 0+"px";
	
	if(cp.style.right == "0px"){
		
		
		cp.style.right = "-110%";
		cp.style.zindex="100";
		eskuma.style.zindex="101";
         js_menu_off.src="images/menua.png";
        eskuma.style.color="#000";
        eskuma.style.position="absolute";
         eskuma.style.right="1em";
        eskuma.style.background="transparent";
        js_logoa.style.position="absolute";
		izenburu.style.opacity = "1";
		headerra.style.opacity = "1";
	} else {
		cp.style.right = "0px";
		
		cp.style.zindex="100";
		eskuma.style.zindex="101";
      eskuma.style.color="#fff";
        eskuma.style.position="fixed";
        eskuma.style.right="0.5em";
        eskuma.style.padding="0.3em";
        eskuma.style.background="blue";
         eskuma.style.textAlign="left";
        js_logoa.style.position="fixed";
        js_menu_off.src="images/menua-x.png";
		
		headerra.style.opacity = "0.3";
	}


}





var $animation_elements = $('.animation-element');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);
 
  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);
 
    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
      $element.addClass('in-view');
    } else {
      $element.removeClass('in-view');
    }
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');